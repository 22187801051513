<template >
  <v-form @submit.prevent="localOnSubmit" ref="formRef" class="mt-5" >
    <v-row >
      <v-col cols="6">
        <v-autocomplete dense :loading="isLoadingPacientes" v-model="pacienteSelecionado" :items="resumoPacientes" item-value="id" item-text="nome" outlined clearable label="Pacientes" @change="updatePacienteData" 
        :disabled="resumoPacientes.length === 1 && (resumoPacientes[0].id === 'no-patients' || resumoPacientes[0].id === 'error')"
        :messages="resumoPacientes.length === 1 && resumoPacientes[0].id === 'no-patients' ? 'Não há pacientes com solicitações de requisição pendente' : ''"
        class="custom-messages"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-text-field dense outlined label="Data de Solicitação da Entrega" disabled v-model="dataRequisicao" type="date"  ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field dense outlined label="Data Presvista de  Entrega" disabled v-model="dataPresvista" type="date"  ></v-text-field>
      </v-col>
    </v-row>

    <v-row dense>
      <v-card-title class="blod_color">Itens Requisitados para Entrega:</v-card-title>
    </v-row>
    <v-row dense>
      <v-col>
        <v-data-table dense :loading="isLoadingPacientes" :headers="ItemPacientelHeaders" :items="filterPaciente" item-value="id" show-select v-model="selectedPacienteItems" class="elevation-1" :items-per-page="-1"  >
        <template v-slot:[`item.equipamento`]="{ item }">
          {{ item.equipamento?.nome }}
        </template>
        <template v-slot:[`item.quantidade`]="{ item }">
          {{ item.quantidade }}
        </template>
    </v-data-table>
      </v-col>
    </v-row>
    <v-row dense >
       <v-col cols="3">
        <v-text-field dense outlined label="Data da Entrega"  v-model="dataEntregaEfetivada" type="date"  ></v-text-field>
      </v-col>
      <v-col md="4">
        <v-file-input v-model="selectedFile" counter show-size persistent-hint dense outlined @change="checkFileSize" ref="fileInput">
          <template v-slot:label>
            <span>Anexar documento <span style="color: red;">*</span></span>
          </template>
        </v-file-input>
      </v-col>
    </v-row>

    <v-row class="justify-end mt-0" dense>
      <v-btn class="mr-4 elevation-0" text @click="atualizarDados" :loading="isLoadingDados"> <v-icon left>mdi-refresh</v-icon> Atualizar  </v-btn>
      <v-col class="text-end">
        <ConfirmButton  :onConfirm="cancelarEntrega" :loading="isLoadingaCancelar" color="red" :disabled="!isSaveEnabledCancelar" :style="{ opacity: isSaveEnabledCancelar ? 1 : 0.5 }" > Cancelar Entrega  </ConfirmButton>
        <ConfirmButton class="ml-5" :loading="loading"  color="success" :disabled="!isSaveEnabled" :style="{ opacity: isSaveEnabled ? 1 : 0.5 }"> Confirmar Entrega</ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>
 
<script>
import api from '../../../../http';
import UtilsFunc from '../../../../service/utilsFunc';
import ConfirmButton from '../../../ConfirmButton.vue';

export default {
  name: "RetirarEquipamentos",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean,
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      ativo: true,
      isLoadingPacientes: false,
      isLoadingaCancelar: false,
      pacientes: [],
      resumoPacientes: [],
      pacienteSelecionado: null,
      dataRequisicao: "",
      dataPresvista: "",
      dataImplantacao: "",
      dataEntregaEfetivada: '',
      nova_disponibilidade: null,
      selectedPacienteItems: [],
      selectedEsterelizaveis: [],
      filterPaciente: [], 
      isLoadingDados: false,
      selectedFile: null,
      ItemPacientelHeaders: [
        { text: "Id Equipamento", value: "equipamento.id" },
        { text: "Equipamento", value: "equipamento.nome" },
        // { text: "Quantidade Solicitada", value: "quantidade" },
      ],
    });
  },
  components: { ConfirmButton },
  methods: {
   async getPacientes() {
      this.isLoadingPacientes = true;
      try {
        const response = await api("pacientes/equipamentos/");
        
        // Filtra apenas os registros relevantes
        this.pacientes = response.data.filter(item => 
          !item.data_implantacao && 
          item.disponibilidade.id === 13 &&
          item.equipamento &&
          item.requisicao === true &&
          item.ativo === true
        );

        if (this.pacientes.length === 0) {
          this.resumoPacientes = [{
            id: 'no-patients',
            nome: 'Não há pacientes com solicitações de troca pendentes'
          }];
        } else {
          // Agrupamento apenas por paciente e data de requisição
          const agrupamento = this.pacientes.reduce((acc, atual) => {
            const dataReq = atual.data_requisicao.split('T')[0];
            const chave = `${atual.paciente.id}-${dataReq}`;

            if (!acc[chave]) {
              const data = new Date(atual.data_requisicao);
              data.setDate(data.getDate() + 1);
              const dataFormatada = data.toLocaleDateString('pt-BR');

              acc[chave] = {
                id: chave,
                idEquipamentoPaciente: atual.id, 
                pacienteId: atual.paciente.id,
                nome: `ID: ${atual.paciente.id} - Nome: ${atual.paciente.nome} - Req (${dataFormatada})`,
                equipamento: [], // Mantemos vazio para inserir cada equipamento separadamente
                data_solicitacao_retirada: atual.data_solicitacao_retirada,
                data_prevista_solicitacao_retirada: atual.data_prevista_solicitacao_retirada,
                data_prevista_implantacao: atual.data_prevista_implantacao
              };
            } 
            
            // Cada equipamento agora será listado separadamente
            acc[chave].equipamento.push({
              id: atual.id,
              idequipamentoPaciente: atual.id,
              equipamento: { 
                nome: atual.equipamento.descricao.nome, 
                id: atual.equipamento.id  
              },
              quantidade: 1
            });

            return acc;
          }, {});

          // Converte para array final e ordena
          this.resumoPacientes = Object.values(agrupamento)
            .map(item => ({
              id: item.id,
              pacienteId: item.pacienteId,
              nome: item.nome,
              equipamento: item.equipamento,
              data_solicitacao_retirada: item.data_solicitacao_retirada,
              data_prevista_solicitacao_retirada: item.data_prevista_solicitacao_retirada,
              data_prevista_implantacao: item.data_prevista_implantacao
            }))
            .sort((a, b) => {
              const dataA = new Date(a.data_solicitacao_retirada);
              const dataB = new Date(b.data_solicitacao_retirada);
              return dataA - dataB;
            });
        }
      } catch (error) {
        this.$toast.error(`Erro ao carregar pacientes. ${error}`);
      } finally {
        this.isLoadingPacientes = false;
      }
    },
    updatePacienteData() {
    this.selectedPacienteItems = [];
    this.filterPaciente = [];

    const paciente = this.resumoPacientes.find(p => p.id === this.pacienteSelecionado);

    if (paciente) {
        this.dataRequisicao = paciente.data_solicitacao_retirada || "";
        this.dataPresvista = paciente.data_prevista_solicitacao_retirada || "";

        // console.log("📌 Paciente Selecionado para entrega:", paciente);

        this.filterPaciente = paciente.equipamento.map(item => ({
            id: item.id,
            equipamento: {
                id: item.equipamento.id,
                nome: item.equipamento.nome,
            },
            quantidade: item.quantidade
        }));

        // console.log("📌 Equipamentos disponíveis para entrega:", this.filterPaciente);

        this.selectedPacienteItems = [...this.filterPaciente];

    } else {
        console.warn("⚠️ Nenhum paciente encontrado com esse ID.");
        this.dataRequisicao = "";
        this.dataPresvista = "";
        this.filterPaciente = [];
    }
}
,
    async updateEquipamento() {
    try {
        const equipamentoPromises = this.selectedPacienteItems.map(async (item) => {
            // console.log(`📌 Atualizando disponibilidade do equipamento ID: ${item.equipamento.id}`);

            try {
                await api.put(`/equipamentos/equipamento_editar/${item.equipamento.id}/`, {
                    id: item.equipamento.id,
                    disponibilidade: 1, // Define como "Disponível"
                });
                // console.log(`✅ Equipamento ${item.equipamento.id} atualizado com sucesso.`);
            } catch (error) {
                console.error(`❌ Erro ao atualizar equipamento ${item.equipamento.id}:`, error);
                this.$toast.error(`Erro ao atualizar equipamento ${item.equipamento.id}.`);
            }
        });

        await Promise.all(equipamentoPromises);
        this.$toast.success("✅ Todos os equipamentos foram atualizados com sucesso.");
    } catch (error) {
        console.error("❌ Erro geral ao atualizar os equipamentos:", error);
        this.$toast.error("Erro ao atualizar os equipamentos.");
    }
}
,

    async updateEquipamentoCancelar() {
      try {
        const promises = this.selectedPacienteItems.map((item) =>
          api.put(`/equipamentos/equipamento_editar/${item.equipamento.id}/`, {
            id: item.equipamento.id,
            disponibilidade: 6,
          })
        );
        await Promise.all(promises); // Aguarda todas as requisições serem concluídas
      } catch (error) {
        console.error('Erro ao atualizar itens do paciente:', error);
        this.$toast.error('Erro ao atualizar itens do paciente.');
      }
    },
    async updatePaciente() {
    try {
        const promises = this.selectedPacienteItems.map(async (item) => {
            // console.log(`📌 Atualizando paciente ID: ${this.pacienteSelecionado}, Equipamento ID: ${item.id}`);

            try {
                await api.put(`/pacientes/equipamento/${item.id}/`, {
                    id: item.id,
                    disponibilidade: 1, // Define como entregue
                    data_implantacao: this.dataEntregaEfetivada,
                    requisicao: null,
                });
                // console.log(`✅ Paciente atualizado com equipamento ID: ${item.id}`);
            } catch (error) {
                console.error(`❌ Erro ao atualizar equipamento ${item.id} no paciente:`, error);
                this.$toast.error(`Erro ao atualizar equipamento do paciente.`);
            }
        });

        await Promise.all(promises);
        this.$toast.success("✅ Todos os equipamentos foram entregues com sucesso.");
    } catch (error) {
        console.error("❌ Erro ao atualizar equipamentos do paciente:", error);
        this.$toast.error("Erro ao atualizar equipamentos do paciente.");
    }
}
,
    async updateDocPaciente() {
        let formData = new FormData();
        formData.append("arquivo", this.selectedFile);
        formData.append("tipodoc", 38);
        formData.append("ativo", true);
        formData.append("paciente", this.pacienteSelecionado.split('-')[0]);
        formData.append("tipopasta", 3);
        const currentDate = new Date().toISOString().split('T')[0];
        formData.append("data_carregado", currentDate);

        try {
          await api.post("/pacientes/docs/", formData);
          this.$toast.success("Documento carregado com sucesso!");
          this.selectedFile = null;
        } catch (error) {
          this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`);
        }
    },
    async updatePacienteCancelar() {
        try {
            const promises = this.selectedPacienteItems.map((item) =>
                api.put(`/pacientes/equipamento/${item.id}/`, {
                    id: item.id,
                    // ativo: true,
                    disponibilidade: 3,
                    equipamento: null
                })
            );
            await Promise.all(promises); // Aguarda todas as requisições serem concluídas
        } catch (error) {
            console.error("Erro ao atualizar Equipamento do Paciente:", error);
            this.$toast.error("Erro ao atualizar Equipamento do Paciente.");
        }
    },
    async localOnSubmit() {
      const validation = this.$refs.formRef.validate();
      if (!validation) return;

      // console.log("📌 Confirmando entrega dos equipamentos selecionados:", this.selectedPacienteItems);

      await this.updateEquipamento();
      await this.updatePaciente();  
      await this.updateDocPaciente();  
      this.$toast.success("📦 Equipamentos entregues com sucesso!");
      this.$emit('atualizar-pagina-pai');
      this.atualizarDados()
    },
      
    async cancelarEntrega() {
      this.isLoadingaCancelar = true
      // console.log("📌 Cancelando entrega dos equipamentos selecionados:", this.selectedPacienteItems);

      await this.updateEquipamentoCancelar();
      await this.updatePacienteCancelar();  
      this.$toast.success("🚫 Entrega cancelada com sucesso!");
      this.$emit('atualizar-pagina-pai');
      this.atualizarDados()
      this.isLoadingaCancelar = false
      this.selectedFile = null
    },
    
    atualizarDados() {
      this.getPacientes()
      this.pacienteSelecionado = null
      this.dataRequisicao = ''
      this.dataPresvista = ''
      this.selectedPacienteItems = []
      this.filterPaciente = []
      this.selectedFile = null


    },
    checkFileSize() {
      if (this.selectedFile && this.selectedFile.size > 2000000) { // maior que 2MB
        this.$toast.error("O tamanho do arquivo é maior do que o permitido (2MB).");
        this.selectedFile = null; // remova a referência do arquivo para que o usuário não possa fazer o upload
        this.$refs.fileInput.reset();
      }
    },
  },
  computed: {
    isSaveEnabled() {
      return (
        this.selectedPacienteItems.length > 0  && 
        this.selectedFile !== null
      );
    },
    isSaveEnabledCancelar() {
      return (
        this.selectedPacienteItems.length > 0 
      );
    }
  },
  mounted() {
    this.getPacientes();
    const hoje = new Date();
    const dataFormatada = hoje.getFullYear() + '-' + 
        String(hoje.getMonth() + 1).padStart(2, '0') + '-' + 
        String(hoje.getDate()).padStart(2, '0');
    this.dataEntregaEfetivada = dataFormatada
  },
};
</script>

<style scoped>
.custom-messages ::v-deep .v-messages__message {
    color: red !important;
    font-size: 16px !important;
    font-weight: bold !important;
}
</style>